export default {
  computed: {
    list() {
      return this.$store.state.moduleVhost.list;
    },
    isLoading() {
      return this.$store.state.moduleVhost.isLoading;
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleVhost/fetchList');
    },
  },
};
