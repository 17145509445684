<template>
  <button class="sidebar-btn" v-on="$listeners">
    <svg-icon name="more" class="sidebar-btn__icon" />
  </button>
</template>

<script>
export default {
  name: 'SidebarButton',
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.sidebar-btn {
  flexy(center, center);
  background: transparent;
  color: var(--text);
  border: 1px solid $color-light.light;
  border-radius: 5px;
  size(3rem);
  box-shadow: none;

  &:focus {
    outline: none;
  }

  &__icon {
    display: block;
    font-size: 1.25rem;
  }
}
</style>
