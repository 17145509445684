<template>
  <!--  <div class="base-specs note-color">-->
  <div class="base-specs">
    <div class="base-specs__list">
      <div v-if="head" class="base-specs__item base-specs__item--head">
        {{ head }}
      </div>
      <div v-for="(item, i) in list" :key="i" class="base-specs__item">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSpecs',
  props: {
    head: {
      type: String,
      default: null,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-specs {

  &__list {
    flexy(flex-start, baseline, wrap);
    margin: -0.25rem -0.5rem;
  }

  &__item {
    margin: 0.25rem 0.5rem;

    &--head {
      flex: 0 0 100%;

      +breakpoint(sm-and-up) {
        flex: 0 0 auto;
      }
    }
  }
}
</style>
