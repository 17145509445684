<template>
  <div class="layout-title">
    <div v-if="img && img.src" class="layout-title__img l-hidden-ms-down">
      <!--      <asset-img :src="img.src" :alt="img.alt || ''" />-->
      <svg-icon size="os" :name="img.src" class="base-btn__icon" />
    </div>
    <div class="layout-title__content">
      <h1 class="layout-title__title page-title">
        <slot name="title">{{ title }}</slot>
      </h1>
      <div class="layout-title__statuses">
        <main-label v-if="status" :color="status.color" class="layout-title__status">
          {{ $t(`status.${status.key}`) }}
        </main-label>
        <main-label v-if="state" :color="state.color" class="layout-title__status">
          {{ $t(`status.${state.key}`) }}
        </main-label>
        <main-label v-if="task" :color="task.color" class="layout-title__status">
          {{ task.key }}
        </main-label>
      </div>
      <div v-if="$slots.btn" class="layout-title__btn l-hidden-md-up">
        <slot name="btn" />
      </div>
      <div v-if="cost" class="layout-title__address medium-text">
        {{ cost }}
      </div>
      <div v-if="id" class="layout-title__address medium-text">
        {{ '#' + id }}
      </div>
      <div v-if="cn" class="layout-title__address medium-text">
        {{ cn }}
      </div>
      <div v-if="address" class="layout-title__address medium-text">
        {{ address }}
      </div>
      <div v-if="created" class="layout-title__address medium-text">
        {{ date(created) }}
        {{ time(created) }}
      </div>
      <base-specs v-if="specs" v-bind="specs" class="layout-title__specs medium-text" />
    </div>
  </div>
</template>

<script>
import MainLabel from '@/components/Labels/MainLabel.vue';
import BaseSpecs from '@/components/Specs/BaseSpecs.vue';
import { format } from 'date-fns';
export default {
  name: 'LayoutTitle',
  components: { MainLabel, BaseSpecs },
  props: {
    img: {
      type: Object,
      default: null,
      validator: obj => !!obj && obj.src,
    },
    title: {
      type: String,
      required: true,
    },
    status: {
      type: Object,
      default: null,
      validator: obj => obj.color && obj.key,
    },
    state: {
      type: Object,
      default: null,
      validator: obj => obj.color && obj.key,
    },
    task: {
      type: Object,
      default: null,
      validator: obj => obj.color && obj.key,
    },
    address: {
      type: String,
      default: null,
    },
    created: {
      type: Date,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    cn: {
      type: String,
      default: null,
    },
    cost: {
      type: String,
      default: null,
    },
    specs: {
      type: Object,
      default: null,
    },
  },
  methods: {
    time(item) {
      if (this.created) return format(item, 'HH:mm:ss');
    },
    date(item) {
      return format(item, 'dd.MM.yyyy');
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "status": {
      "stopped": "Выключен",
      "suspended": "Неактивен",
      "running": "Включён"
    }
  }
}
</i18n>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.layout-title {
  flexy(flex-start, center);

  &__img {
    flex: 0 0 2.5rem;
    margin-right: 1.25rem;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__content {
    flexy(flex-start, center, wrap);
    width: 100%;

    &::after {
      +breakpoint(sm-and-up) {
        content: '';
        display: block;
        order: 3;
        flex: 1 1 100%;
      }
    }
  }

  &__title {
    flex: 0 0 calc(100% - 4rem);
    max-width: calc(100% - 4rem);
    order: 1;
    margin-bottom: 1rem;
    word-break: break-word;

    +breakpoint(sm-and-up) {
      flex: 1 0 100%;
      max-width: calc(100% - 3rem);
      padding-right: 0;
      margin-bottom: 0.25rem;
    }

    +breakpoint(md-and-up) {
      flex: 1 0 auto;
      max-width: 100%;
    }
  }

  &__btn {
    flex: 0 0 auto;
    order: 2;
    margin: 0 0 0 auto;
    align-self: flex-start;
  }

  &__statuses {
    flex: 1 0 auto;
    order: 3;
    margin: -0.25rem -0.25rem 1rem;

    +breakpoint(sm-and-up) {
      margin-top: 0.5rem;
    }

    +breakpoint(md-and-up) {
      flex: 0 0 auto;
      order: 5;
      margin: -0.25rem 1rem 0;
    }
    +breakpoint(lg-and-up) {
      order: 2;
    }

  }

  &__status {
    margin: 0.25rem;
  }

  &__address {
    order: 3;
    flex: 0 0 auto;
    margin: 0 auto 0 0;

    +breakpoint(sm-and-up) {
      order: 4;
      margin: 0 1.25rem 0 0;
    }
  }

  &__specs {
    flex: 0 0 100%;
    margin-top: 1.5rem;
    order: 5;

    +breakpoint(sm-and-up) {
      flex: 1 1 auto;
      margin-top: 0;
    }
  }

}
</style>
